import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { Page } from 'src/app/shared/models/page.model';
import { UserRole } from 'src/app/shared/models/user-role.enum';
import { User } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  currentUser: User | any | undefined = undefined;
  isDrawerVisible: boolean = false;

  pages: Page[] = [
    { icon: 'home', name: 'Dashboard', path: 'app/dashboard', selected: false },
    { icon: 'team', name: 'Risorse', path: 'app/resources', selected: false },
    { icon: 'shopping', name: 'Aziende', path: 'app/companies', selected: false },
    { icon: 'bulb', name: 'Iniziative', path: 'app/initiative', selected: false },
  ];

  role = UserRole;
  version = 'v1.2.4';
  constructor(private authService: AuthService, private router: Router) {
    this.currentUser = this.authService.currentUser.value;
    if (this.currentUser?.roles[0].id === UserRole.Employee) {
      this.pages = [
        { icon: 'home', name: 'Dashboard', path: 'app/dashboard', selected: false },
        { icon: 'home', name: 'Curriculum', path: 'app/resources/cv/' + this.currentUser.resource_id, selected: false },
      ];
    } else if (this.currentUser?.roles[0].id === UserRole.Admin) {
      this.pages = [{ icon: 'home', name: 'Dashboard', path: 'app/dashboard', selected: false },
      { icon: 'team', name: 'Risorse', path: 'app/resources', selected: false },
      { icon: 'shopping', name: 'Aziende', path: 'app/companies', selected: false },
      ];
    }
    else if (this.currentUser?.roles[0].id === UserRole.Administrative) {
      this.pages = [{ icon: 'home', name: 'Dashboard', path: 'app/dashboard', selected: false },
      { icon: 'team', name: 'Risorse', path: 'app/resources', selected: false },
      { icon: 'shopping', name: 'Profilo', path: `app/companies/${this.currentUser?.resource?.company_id}`, selected: false },
      { icon: 'solution', name: 'Richieste', path: 'app/request', selected: false },
      ];
    }
    else if (this.currentUser?.roles[0].id === UserRole.Company) {
      this.pages = [
        { icon: 'home', name: 'Dashboard', path: 'app/dashboard', selected: false },
        { icon: 'team', name: 'Risorse', path: 'app/resources', selected: false },
        { icon: 'shopping', name: 'Profilo', path: `app/companies/${this.currentUser?.company?.id}`, selected: false },
        { icon: 'shopping', name: 'Iniziative', path: 'app/initiative', selected: false }
      ];
    }


    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      let _url = event.urlAfterRedirects;

      this.pages.filter(page => page.selected == true).forEach(page => page.selected = false);


      let el = this.pages.find(page => { return _url.includes(page.path?.split('/').pop()) });
      if (el) el.selected = true;
    });
  }

  ngOnInit(): void { }

  logout() {
    this.authService.logout().subscribe((res) => {
      this.authService.currentUser.next(null);
      localStorage.removeItem('csrf');
      this.router.navigate(['/auth/login']);
    });
  }

  changeDrawerStatus() {
    this.isDrawerVisible = !this.isDrawerVisible;
  }

  selectPage() {
    this.isDrawerVisible = !this.isDrawerVisible;
  }
}
