// Angular
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpContextToken, HttpContext
} from '@angular/common/http';
// RxJS
import { map, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoaderService } from './loader.service';
import { Router } from '@angular/router';
import { toast } from "../../app.component";
import { TranslateService } from '@ngx-translate/core';
import { HttpContextConfig } from '../models/http-context-config';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */

export const CONFIG = new HttpContextToken<HttpContextConfig>(() => new HttpContextConfig())

@Injectable({
  providedIn: 'root'
})

export class InterceptorService implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private translate: TranslateService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let storageData = localStorage.getItem('access_token') || '{}';

    // set withCredentials to true to send cookies
    request = request.clone({
      withCredentials: true
    });

    if (JSON.parse(storageData)?.csrf_token) {
      request = request.clone({
        setHeaders: {
          'csrf': JSON.parse(storageData)?.csrf_token,
          'ngrok-skip-browser-warning': "true",
        },
        withCredentials: true
      });
    }

    // check if the request have NO_SPINNER context
    if (request.context.get(CONFIG)) {
      let config = request.context.get(CONFIG);
      if (!config.noSpinner) {
        this.loaderService.setLoading(true, request.url);
      }

      if (config.isPublic) {
        // console.log("this is a public request");
        /*this.translate.get(['toast.info.100_TITLE', 'toast.info.100_TEXT']).subscribe(message =>
          toast.fire({
            title: message['toast.info.100_TITLE'],
            text: message['toast.info.100_TEXT'],
            icon: 'info'
          }
          )
        );*/
      }
    }

    return next.handle(request).pipe(
      map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
          this.loaderService.setLoading(false, request.url);

          if (request.url.indexOf('/protected') != -1) {
            if (request.method != 'GET')
              toast.fire({
                icon: 'success',
                title: evt.body.message
              })
          }
        }
        return evt;
      })
    )
      .pipe(tap((event) => { }),
        catchError((err) => {
          let config = request.context.get(CONFIG);
          this.loaderService.setLoading(false, request.url);

          if (config.isInitializer) {
            return of<any>(null);
          }

          if (new RegExp('[4,5]0[0-5]').test(err.status)) {
            toast.fire({
              title: 'Ops!',
              html: (err.error.message instanceof Object) ?
                (Object.values(err.error.message).join("<br>")) :
                (err.error.message),
              icon: 'error'
            });
          } else {
            this.translate.get(['TOAST.ERROR.general_TITLE', 'TOAST.ERROR.GENERAL_TEXT']).subscribe(message =>
              toast.fire({
                title: 'Ops!',
                text: 'C\'è stato un errore',
                icon: 'error'
              }
              ));
          }

          if (err.status === 401) {
            this.router.navigate(['/auth/login']);
          }
          if (err.status === 403) {
            history.back();
          }
          return throwError(err.error);
        })
      )
  }
}
