<div *ngIf="loading" class="loading-container flex-content-center flex-center">
  <mat-progress-spinner class="m-auto mat-spinner-color" mode="indeterminate" diameter="70"></mat-progress-spinner>
</div>

<div class="page-body d-flex flex-column justify-content-between">
  <app-header *ngIf="components.header"></app-header>
  <div *ngIf="components.sidebar; else noSidebar" class="d-flex flex-row">
    <app-sidebar *ngIf="components.sidebar"></app-sidebar>
    <router-outlet></router-outlet>
  </div>
  <ng-template #noSidebar>
    <div class="d-flex flex-column flex-grow-1" [ngClass]="{'mt-5 pt-3': needsMargin}">
      <router-outlet></router-outlet>
    </div>
  </ng-template>
  <app-footer *ngIf="components.footer"></app-footer>
</div>