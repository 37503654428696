<div class="d-flex" id="wrapper">
  <div class="border-end bg-white" id="sidebar-wrapper">
    <div class="list-group list-group-flush">
      <a *ngFor="let item of list" class="list-group-item list-group-item-action list-group-item-light p-3" href="{{item.link}}">
        <i class="fas fa-{{item.icon}}"></i>
        <span class="p-3">{{item.name}}</span>
      </a>
    </div>
  </div>
</div>
