<footer class="footer" [ngSwitch]="true" [style.width]="width">
    <div [ngClass]="{'mx-4': width != '80vw'}">
        <div *ngSwitchCase="service.mode.value == Mode.LIST" class="mb-sm-5 d-flex flex-row justify-content-center">
            <button class="btn btn-primary" [routerLink]="'new'" (click)="setCreate()">
                <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
                Aggiungi {{ type }}</button>
        </div>


        <div *ngSwitchCase="service.mode.value == Mode.CREATE" class="mb-sm-5 d-flex flex-row justify-content-between">
            <div>
                <!-- Button Indietro -->
                <button class="btn btn-outline-primary d-none d-sm-block" (click)="goBack()">
                    <span class="me-2"><i class="fas fa-chevron-left"></i></span> Indietro
                </button>
                <button class="btn btn-outline-primary d-block d-sm-none" (click)="goBack()">
                    <span><i class="fas fa-chevron-left"></i></span>
                </button>
            </div>
            <div class="d-flex">
                <button class="btn btn-primary me-4 d-none d-sm-block" (click)="save()">
                    Salva
                </button>
                <button class="btn btn-primary me-4 d-block d-sm-none" (click)="save()">
                    <i class="fas fa-save"></i>
                </button>
            </div>
        </div>


        <div *ngSwitchCase="[Mode.EDIT, Mode.SHOW].includes(service.mode.value)" class="mb-sm-5 d-flex flex-row"
            [ngClass]="{'justify-content-between': true}">

            <div>
                <!-- Button Indietro -->
                <button class="btn btn-outline-primary d-none d-sm-block" (click)="goBack()">
                    <span class="me-2"><i class="fas fa-chevron-left"></i></span> Indietro
                </button>
                <button class="btn btn-outline-primary d-block d-sm-none" (click)="goBack()">
                    <span><i class="fas fa-chevron-left"></i></span>
                </button>
            </div>

            <div class="d-flex">
                <button [disabled]="isHiddenModify" class="btn btn-outline-primary me-4 d-none d-sm-block"
                    (click)="edit()">
                    <span nz-icon nzType="edit" nzTheme="outline"></span> Modifica
                </button>
                <button [disabled]="isHiddenModify" class="btn btn-outline-primary me-4 d-block d-sm-none"
                    (click)="edit()">
                    <i class="fas fa-edit"></i>
                </button>

                <button class="btn btn-primary d-none d-sm-block" (click)="save()"
                    [disabled]="service.mode.value == Mode.SHOW">
                    Salva
                </button>
                <button class="btn btn-primary d-block d-sm-none" (click)="save()"
                    [disabled]="service.mode.value == Mode.SHOW">
                    <i class="fas fa-save"></i>
                </button>
            </div>
        </div>


        <div *ngSwitchCase="service.mode.value == Mode.CV" class="mb-sm-5 d-flex flex-row justify-content-between">
            <div>
                <!-- Button Indietro -->
                <button class="btn btn-outline-primary d-none d-sm-block" (click)="goBack()">
                    <span class="me-2"><i class="fas fa-chevron-left"></i></span> Indietro
                </button>
                <button class="btn btn-outline-primary d-block d-sm-none" (click)="goBack()">
                    <span><i class="fas fa-chevron-left"></i></span>
                </button>
            </div>
            <div class="d-flex">
                <button class="btn btn-outline-primary me-4 d-none d-sm-block" *ngIf="externalFile"
                    (click)="printCV(PrintMode.EXTERNAL)">
                    Scarica il CV esterno
                </button>
                <button class="btn btn-outline-primary me-4 d-block d-sm-none" *ngIf="externalFile"
                    (click)="printCV(PrintMode.EXTERNAL)">
                    <i class="fas fa-external-link-alt"></i>
                </button>

                <!-- <button class="btn btn-outline-primary me-4" (click)="showAnonymousModal()"> Scarica il CV anonimo</button> -->

                <button class="btn btn-outline-primary me-4 d-none d-sm-block" (click)="showCompleteModal()">
                    Scarica il CV
                </button>
                <button class="btn btn-outline-primary me-4 d-block d-sm-none" (click)="showCompleteModal()">
                    <i class="fas fa-download"></i>
                </button>
            </div>
        </div>
    </div>
</footer>

<!--Modal Stampa-Completa-->
<nz-modal [(nzVisible)]="isVisibleComplete" nzTitle="Scarica il CV" (nzOnCancel)="handleCancelComplete()"
    (nzOnOk)="printValue =='full'? printCV(PrintMode.FULL):printCV(PrintMode.ANONYMOUS)">
    <ng-container *nzModalContent>
        <div class="d-flex flex-column">
            <nz-radio-group class="mb-2" [(ngModel)]="printValue" (ngModelChange)="changePrintRadio($event)">
                <label nz-radio [nzDisabled]="(anonymous | async)" nzValue="full">Non Anonimo</label>
                <label nz-radio nzValue="anonymous">Anonimo</label>
            </nz-radio-group>
            <p class="mt-2">Selezionare il tipo di stampa</p>
            <nz-radio-group [(ngModel)]="radioValue" (ngModelChange)="changeRadio($event)">
                <label [nzDisabled]="printValue == 'anonymous' || (anonymous | async)" nz-radio
                    nzValue="retro">Retrodatata</label>
                <label nz-radio nzValue="complete">Fino ad oggi</label>
            </nz-radio-group>
        </div>
        <div class="mt-4" *ngIf="radioValue == 'retro'">
            <p>Seleziona l'anno</p>
            <nz-select [(ngModel)]="cv_date" nzPlaceHolder="Scegli...">
                <nz-option *ngFor="let item of cv_years" [nzValue]="item.value" [nzLabel]="item.label">
                </nz-option>
            </nz-select>
        </div>
    </ng-container>
</nz-modal>

<!--Modal Stampa-Anonima-->
<nz-modal [(nzVisible)]="isVisibleAnonymous" nzTitle="Scarica il CV anonimo" (nzOnCancel)="handleCancelAnonymous()"
    (nzOnOk)="printCV(PrintMode.ANONYMOUS)">
    <ng-container *nzModalContent>
        <p>Selezionare il tipo di stampa</p>
        <nz-radio-group [(ngModel)]="radioValue" (ngModelChange)="changeRadio($event)">
            <label nz-radio nzValue="retro">Retrodatata</label>
            <label nz-radio nzValue="complete">Attuale</label>
        </nz-radio-group>
        <div class="mt-4" *ngIf="radioValue == 'retro'">
            <p>Seleziona l'anno</p>
            <nz-select [(ngModel)]="cv_date" nzPlaceHolder="Scegli...">
                <nz-option *ngFor="let item of cv_years" [nzValue]="item.value" [nzLabel]="item.label">
                </nz-option>
            </nz-select>
        </div>
    </ng-container>
</nz-modal>