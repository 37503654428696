export interface Params {
  type: string,
  page_index: number,
  page_size: number,
  order_by: string,
  order_dir: string,
  company_id?: string
}

export enum Type {
  COMPANIES = 'companies',
  COMPANY = 'company',
  PROJECTS = 'projects'
}

export interface StaticData {
  id: string;
  name: string;
  display_name: string;
}

export enum Mode {
  CREATE = 'create',
  SHOW = 'show',
  EDIT = 'edit',
  VARIATION = 'variation',
  LIST = 'list',
  CONFIGURATION = 'configuration',
  CREATE_CONFIGURATION = 'create_configuration',
  CV = 'cv'
}

export enum PrintMode {
  ANONYMOUS = 1,
  FULL = 0,
  EXTERNAL = 2
}

export enum ListType {
  RESOURCE = 'resource',
  COMPANY = 'company',
  COMPANY_COUNTERS = 'company-counters',
  PROJECT = 'project',
  INITIATIVE = 'initiative',
  RESOURCE_INITIATIVE = 'resource_initiative',
  REQUEST = 'request',
  REQUEST_EMPLOYEE = 'request_employee'
}