import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormattedResponse } from '../models/formatted-response';
import { Resource } from '../models/resource.model';
import { Mode } from "../models/utils.model";
import { BehaviorSubject, Observable } from "rxjs";
import { NotificationUpdateCV } from '../models/notification-update-cv.model';

@Injectable({
    providedIn: 'root'
})
export class ResourcesServices {
    route = `${environment.api}`;
    mode: BehaviorSubject<Mode> = new BehaviorSubject<Mode>(Mode.CREATE);
    constructor(private http: HttpClient) { }

    getResources(params?: any) {
        return this.http.get<FormattedResponse<Resource[]>>(`${this.route}/resources`, { params });
    }

    getResourcesConsorzio(params?: any) {
        return this.http.get<FormattedResponse<Resource[]>>(`${this.route}/consorzio`, { params });
    }

    getResourceById(id: string) {
        return this.http.get<FormattedResponse<Resource>>(`${this.route}/resources/${id}`);
    }

    createResource(data: Resource) {
        return this.http.post<FormattedResponse<Resource>>(`${this.route}/resources`, data);
    }

    editResource(id: string, data: Resource) {
        return this.http.put<FormattedResponse<Resource>>(`${this.route}/resources/${id}`, data);
    }

    deleteResource(id: string) {
        return this.http.delete<FormattedResponse<Resource>>(`${this.route}/resources/${id}`);
    }

    getWorkExperienceById(id: string) {
        return this.http.get<FormattedResponse<Resource>>(`${this.route}/resources/${id}/experiences`);
    }

    createWorkExperience(id: string, data: any) {
        return this.http.post<FormattedResponse<Resource>>(`${this.route}/resources/${id}/experiences`, data);
    }

    updateWorkExperience(id: string, data: any) {
        return this.http.put<FormattedResponse<Resource>>(`${this.route}/resources/${id}/experiences/update`, data);
    }

    removeWorkExperience(id: string, project_id: string) {
        return this.http.delete<FormattedResponse<Resource>>(`${this.route}/resources/${id}/experiences/${project_id}`);
    }

    removeProjectWorkExperience(id: string, project_id: string) {
        return this.http.delete<FormattedResponse<Resource>>(`${this.route}/resources/${id}/experiences/projects/${project_id}`);
    }

    updateQualifications(id: string, data: any) {
        return this.http.put<FormattedResponse<Resource>>(`${this.route}/resources/${id}/qualifications/update`, data);
    }

    updateSkillsLanguage(id: string, data: any) {
        return this.http.put<FormattedResponse<Resource>>(`${this.route}/resources/${id}/languages/update`, data);
    }

    getTechnologies(id: string) {
        return this.http.get<FormattedResponse<Resource>>(`${this.route}/resources/${id}/technologies`);
    }

    getAllQualifications(query: string) {
        return this.http.get<FormattedResponse<any>>(`${this.route}/qualifications?query=${query}`);
    }

    getAllInstitutes(query: string) {
        return this.http.get<FormattedResponse<any>>(`${this.route}/institutes?query=${query}&&take=15`);
    }

    getTechnologieExperiences(data: any, id: string) {
        return this.http.get<FormattedResponse<Resource>>(`${this.route}/resources/${id}/technologies/workExperiences?query=${data}`);
    }

    updateTechnologies(data: any, id: string) {
        return this.http.put<FormattedResponse<Resource>>(`${this.route}/resources/${id}/technologies/update`, data);
    }

    listTechnologies() {
        return this.http.get<FormattedResponse<Resource>>(`${this.route}/technologies/list`);
    }

    searchTechnology(data: any) {
        return this.http.get<FormattedResponse<Resource>>(`${environment.api}/technologies/search?query=${data}`);
    }

    updateHobbyInterests(id: string, data: any) {
        return this.http.put<FormattedResponse<Resource>>(`${this.route}/resources/${id}/hobby/update`, data);
    }

    updateConferenceSeminar(id: string, data: any) {
        return this.http.put<FormattedResponse<Resource>>(`${this.route}/resources/${id}/conferences_seminars/update`, data);
    }

    getSkills(id: string) {
        return this.http.get<FormattedResponse<Resource>>(`${this.route}/resources/${id}/other_skills`);
    }

    updateSkills(id: string, data: any) {
        return this.http.put<FormattedResponse<Resource>>(`${this.route}/resources/${id}/other_skills/update`, data);
    }

    getOtherExperiences(id: string) {
        return this.http.get<FormattedResponse<Resource>>(`${this.route}/resources/${id}/otherExperiences`);
    }

    updateOtherExperiences(id: string, data: any) {
        return this.http.put<FormattedResponse<Resource>>(`${this.route}/resources/${id}/otherExperiences/update`, data);
    }

    updatePublications(id: string, data: any) {
        return this.http.put<FormattedResponse<Resource>>(`${this.route}/resources/${id}/publications/update`, data);
    }

    updateCertifications(id: string, data: any) {
        return this.http.put<FormattedResponse<Resource>>(`${this.route}/resources/${id}/certifications/update`, data);
    }

    postNotification(id: string, data: any) {
        return this.http.post<FormattedResponse<Resource>>(`${this.route}/resources/request/update/${id}`, data);
    }

    getNotification(params: any) {
        return this.http.get<FormattedResponse<NotificationUpdateCV>>(`${this.route}/resources/request/update/notification`, { params });
    }

    getNotificationsResource(id: string, params: any) {
        return this.http.get<FormattedResponse<NotificationUpdateCV>>(`${this.route}/resources/request/notifications/${id}`, { params });
    }

    getAllRequestUpdate(params?: any) {
        return this.http.get<FormattedResponse<NotificationUpdateCV>>(`${this.route}/listRequest`, { params });
    }

    responseNotification(notification_id: any, id: any) {
        return this.http.post<FormattedResponse<Resource>>(`${this.route}/resources/request/lastupdate/${notification_id}/${id}`, null);
    }

    getDriverLicenses(data?: any) {
        return this.http.get<FormattedResponse<Resource[]>>(`${this.route}/driver_licenses`);
    }

    getDriverLicensesResources(id: string) {
        return this.http.get<FormattedResponse<Resource[]>>(`${this.route}/resources/${id}/driver_licenses`);
    }

    saveDrive(id: string, data: any) {
        return this.http.post<FormattedResponse<Resource>>(`${this.route}/resources/${id}/driver_licenses/${data}`, data);
    }

    deleteDrive(resource_id: string, driver_license_id: string) {
        return this.http.delete<FormattedResponse<Resource>>(`${this.route}/resources/${resource_id}/driver_licenses/${driver_license_id}`);
    }

    updatePrivacy(resource_id: string, privacy_value: boolean) {
        return this.http.put<FormattedResponse<Resource>>(`${this.route}/resources/${resource_id}/update_privacy`, { 'privacy': privacy_value });
    }

    getLastNotification(id: string) {
        return this.http.get<FormattedResponse<Resource>>(`${this.route}/resources/request/lastupdate/${id}`);
    }

    printCV(id: string, anonymous: number, end_date?: Date) {
        return this.http.get<FormattedResponse<Resource>>(`${this.route}/resources/${id}/printCV/${anonymous}?end_date=${end_date}`, {
            'headers': {
                'toast': 'hidden',
                'Content-Type': 'application/json', 'customHeader': 'blob'
            }, 'responseType': "blob" as "json"
        });
    }


    printResource(data: any): Observable<Blob> {
        const headers = new HttpHeaders({
            'toast': 'hidden',
            'Content-Type': 'application/json',
            'customHeader': 'blob'
        });

        const params = new HttpParams({ fromObject: data });

        return this.http.get(`${this.route}/printResource`, {
            headers: headers,
            responseType: 'blob',
            params: params
        });
    }

    printAllResourceCV(data: any): Observable<any> {
        const headers = new HttpHeaders({
            'toast': 'hidden',
            'Content-Type': 'application/json',
            'customHeader': 'blob'
        });

        const params = new HttpParams({ fromObject: data });

        return this.http.get(`${this.route}/printAllResourceCV`, {
            headers: headers,
            params: params
        });
    }

    exportZip(data: any) {
        return this.http.post<FormattedResponse<any>>(`${this.route}/pdf/create-zip`, data);
    }

    checkYears(data: any) {
        return this.http.post<FormattedResponse<any>>(`${this.route}/yearExperience`, data);

    }
    getNationalitiesByQuery(params: string) {
        return this.http.get<FormattedResponse<any>>(`${this.route}/nationalities?query=${params}&take=100`);
    }

    getProvincesByQuery(params: string) {
        return this.http.get<FormattedResponse<any>>(`${this.route}/provinces?query=${params}&take=100`);
    }

    getCapByQuery(params: string) {
        return this.http.get<FormattedResponse<any>>(`${this.route}/caps?query=${params}&take=100`);
    }

    getRolesByQuery(params: any) {
        return this.http.get<FormattedResponse<any>>(`${this.route}/professional_roles`, { params });
    }

    uploadCurriculum(data: any) {
        return this.http.post<FormattedResponse<any>>(`${this.route}/resources/curriculum`, data);
    }

    downloadExternalCurriculum(resource_id: string) {
        return this.http.get<any>(`${this.route}/resources/${resource_id}/curriculum`, {
            'headers': {
                'toast': 'hidden',
                'Content-Type': 'application/json', 'customHeader': 'blob'
            }, 'responseType': "blob" as "json"
        });
    }

    getAllCvYears(resource_id: string) {
        return this.http.get<FormattedResponse<any>>(`${this.route}/resources/${resource_id}/getAllCvYear`);
    }

    reUploadCV(resource_id: string, data: any) {
        return this.http.post<FormattedResponse<any>>(`${this.route}/resources/${resource_id}/reUploadCV`, data);
    }

}
