import { Injectable, Component, OnInit } from '@angular/core';
import { FormArray, FormControl, ValidationErrors } from '@angular/forms';
import { Mode, PrintMode, ListType } from 'src/app/shared/models/utils.model';
import { BehaviorSubject } from "rxjs";
import { UserRole } from 'src/app/shared/models/user-role.enum';

@Injectable()
export class BaseComponentDependences {
  constructor(
  ) { }
}

@Component({
  selector: 'app-base-component',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})

export class BaseComponent implements OnInit {
  Mode = Mode;
  UserRole = UserRole;
  PrintMode = PrintMode;
  ListType = ListType;

  regex = {
    name: "^([a-zA-Z\xE0\xE8\xE9\xF9\xF2\xEC\x27\ s]?)+$",
    email: "[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}",
    password: "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$",
    phone: "^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{3,4})(?: *x(\\d+))?\\s*$",
    mobile: "^([+]39)?\\s?((313)|(32[0123456789])|(33[013456789])|(35[0123456789])|(34[0123456789])|(36[0368])|(37[0123456789])|(38[0389])|(39[0123]))[\\s-]?([\\d]{7})$",
    vatNumber: "([0-9]{11})",
    fiscal_code: "^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$",
    url: "https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)",
    vatNumberFiscalCode: "^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$|([0-9]{11})",
    uuid: "^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$"
  }

  showValidatorsError(form: any, compiled = false) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if ((compiled && control.value) || !compiled) control.markAsTouched({ onlySelf: true });
    });
  }

  contracts = [
    { id: 'full-time', name: 'Full-time' },
    { id: 'part-time', name: 'Part-time' },
    { id: 'collaboration', name: 'Distacco' }
  ]

  constructor() { }

  ngOnInit() {
  }

  getNestedKey(row: any, key: string) {
    let keys = key.split('.');
    let value = row;
    if (keys.length > 1) {
      keys.forEach((key) => {
        if (value[key] != undefined || value[key] != null) {
          value = value[key]
        }
      })
    } else {
      value = row[key];
    }

    return typeof (value) != 'object' ? value : '---';
  }

  /** refreshData deve essere richiamato dentro il subscribe dei vari getElementById dei servizi
   * (es.: getResourceById, getCompanyById, ecc.) per aggiornare correttamente i vari elementi della pagina
   */
  refreshData(service: any, element_observer: BehaviorSubject<any>, breadcrumbs: any, data: any, name: string) {
    element_observer.next(data);
    service.mode.next(Mode.SHOW);

    // caso in cui stiamo modificando l'elemento: il breadcrumb è già stato aggiunto
    // e va fatto il pop del vecchio prima di inserire il nuovo
    if (breadcrumbs.length > 1)
      breadcrumbs.pop();
    breadcrumbs.push({ name: name, path: '', active: true });
  }

  getFormArrayLength(formArray: any): number {
    return (formArray as FormArray).length;
  }

  getColor(index: number): string {
    let color: string = index % 3 == 0 ? 'blue' : index % 2 == 0 ? 'yellow' : 'pink';
    return color;
  }
}
