import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsRoutingModule } from './components-routing.module';
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { DataTableComponent } from './data-table/data-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CardComponent } from './card-list/card/card.component';
import { CardListComponent } from './card-list/card-list.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CustomFormComponent } from './custom-form/custom-form.component';
import { NgZorroAntdModule } from './ng-zorro-antd/ng-zorro-antd.module';
import { CollapseComponent } from './collapse/collapse.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DatePipe } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ListComponent } from './list/list.component'
import { NgChartsModule } from 'ng2-charts';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { CurrencyDisplayFormatPipe } from '../shared/pipes/currencyDisplayFormat.pipe';




@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    DataTableComponent,
    DashboardComponent,
    CardComponent,
    CardListComponent,
    BreadcrumbsComponent,
    CustomFormComponent,
    CollapseComponent,
    ListComponent,
    CurrencyDisplayFormatPipe

  ],
  imports: [
    CommonModule,
    ComponentsRoutingModule,
    TranslateModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    FormsModule,
    SweetAlert2Module,
    MatTabsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    MatAutocompleteModule,
    NgChartsModule,
    MatCheckboxModule
  ],
  exports: [
    CommonModule,
    ComponentsRoutingModule,
    TranslateModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    FormsModule,
    SweetAlert2Module,
    MatTabsModule,
    ReactiveFormsModule,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    DataTableComponent,
    DashboardComponent,
    CardComponent,
    CardListComponent,
    BreadcrumbsComponent,
    CustomFormComponent,
    NgZorroAntdModule,
    CollapseComponent,
    ImageCropperModule,
    MatAutocompleteModule,
    ListComponent,
    NgChartsModule,
    MatCheckboxModule,
    ListComponent,
    ],

  providers: [
    DatePipe,
    ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }
