import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('../app/modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'app', loadChildren: () => import('../app/modules/application/application.module').then(m => m.ApplicationModule), canActivate: [AuthGuard] }, //
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: '*', redirectTo: '' }
];

/*if(config.sidebar) {
  console.log('primo routing');
  routes = [
    { path: '', component: SidebarComponent, children: [
        { path: '', component: ContentComponent, children: []},
      ]},
    { path: '', component: FooterComponent, children: []},
  ];
} else {
  console.log('secondo routing');
  routes = [
    { path: '', component: HeaderComponent, children: [
        { path: '', component: ContentComponent, children: []},
        { path: '', component: FooterComponent, children: []},
      ]},
  ];
}*/

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
