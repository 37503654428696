<section class="mb-4 mb-xl-0 bg-light d-none d-lg-block">
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid py-0 py-xl-auto">
      <div class="d-flex flex-row justify-content-xl-between">
        <a class="navbar-brand" href="app/dashboard"><img src="../../../assets/imgs/logo/zaffiro-logo.svg"></a>

        <ul nz-menu nzMode="horizontal" class="d-none d-xl-block">
          <li nz-menu-item *ngFor="let page of pages" [routerLink]="page.path" [nzSelected]="page.selected">
            <span nz-icon [nzType]="page.icon!"></span>
            {{ page.name }}
          </li>
        </ul>
      </div>

      <div class="btn-group me-5">
        <button type="button" class="btn dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown"
          aria-expanded="false">
          <button type="button" class="btn">
            <nz-avatar class="text-uppercase mx-2" [ngStyle]="{ 'background-color': '#93c8ff', 'color': '#801B3D' }"
              [nzText]="currentUser?.name?.charAt(0) ?? currentUser?.business_name?.charAt(0)"></nz-avatar>
            <span>{{ currentUser?.name ?? currentUser?.business_name || 'Utente' }}</span>
          </button>
        </button>

        <ul class="custom-dropdown dropdown-menu">
          <li>
            <a routerLink="/app/profile"><i class="btn fa-solid fa-gear text-muted"></i> Impostazioni</a>
          </li>
          <li>
            <div *ngIf="currentUser?.roles[0].id === role.Admin">
          <li>
            <hr class="dropdown-divider">
          </li>
          <li>
            <a routerLink="/app/admin/users"><i class="btn fa-solid fa-gear text-muted"></i> Amministrazione</a>
          </li>
      </div>
      </li>
      <li>
        <hr class="dropdown-divider">
      </li>
      <div class="d-flex flex-row justify-content-start mx-3">
        <span class="cursor-pointer" (click)="logout()">Esci</span>
      </div>
      </ul>
    </div>
    </div>

    <div class="d-xl-none col-12 px-0">
      <ul nz-menu nzMode="horizontal">
        <li nz-menu-item *ngFor="let page of pages" [routerLink]="page.path" [nzSelected]="page.selected">
          <span nz-icon [nzType]="page.icon!"></span>
          {{ page.name }}
        </li>
      </ul>
    </div>
  </nav>
</section>

<nav class="navbar bg-white fixed-top d-block d-lg-none">
  <div class="container justify-content-between">
    <a class="navbar-brand ms-3" href="app/dashboard">
      <img class="logo" src="../../../assets/imgs/logo/zaffiro-logo.svg">
    </a>

    <button class="btn btn-outline-secundary" type="button" aria-controls="offcanvasNavbar"
      (click)="changeDrawerStatus()">
      <!-- data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" -->
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- </div> -->
  </div>
</nav>

<nz-drawer [nzClosable]="false" [nzVisible]="isDrawerVisible" nzPlacement="right" nzTitle="Menu"
  [nzCloseOnNavigation]="true" (nzOnClose)="changeDrawerStatus()">
  <!-- <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel"> 
  <div class="offcanvas-header justify-content-end">
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body"> -->
  <ng-container *nzDrawerContent>
    <ul class="navbar-nav justify-content-start flex-grow-1 pe-3">
      <li class="nav-item" *ngFor="let page of pages">
        <a class="nav-link active" aria-current="page" [routerLink]="page.path" (click)="selectPage()">
          <span class="text-capitalize">{{ page.name }}</span>
        </a>
      </li>
      <li *ngIf="this.currentUser?.roles[0].id == role.Admin" class="nav-item"> <a class="nav-link" aria-current="page"
          routerLink="/app/admin/users">
          <span> Amministrazione </span>
        </a>
      </li>
      <li class="nav-item" (click)="logout()">
        <a class="nav-link active" aria-current="page">
          <span class="text-capitalize">Esci</span>
        </a>
      </li>
    </ul>
  </ng-container>
  <!-- </div> -->
</nz-drawer>