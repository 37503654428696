export const config = {
  header: {
    active: true,
    exception: ["/auth/login"]
  },
  sidebar: {
    active: false,
    exception: []
  },
  footer: {
    active: false,
    exception: []
  },
  resource: {
    add: {
      custom_password: false,
      password: 'Zaffiro123!'
    },
    exception: []
  }
}
